import styled from 'styled-components';

export const Container = styled.div``;

export const Check = styled.div``;

export const CheckPosition = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  font-family: 'Neuzeit Grotesk', sans-serif;
  font-size: 18px;
  font-weight: 400;
`;

export const Total = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 8px;
  font-family: 'Utopia Std', serif;
  font-size: 20px;
  font-weight: 400;
  border-top: 1px solid #6677aa;
`;

export const Text = styled.span`
  color: #001c72;
`;

export const Button = styled.button`
  margin: 0 auto;
  background: ${({ disabled }) => (disabled ? '#c95865' : '#bc2e3e')};
  border-radius: 4px;
  max-width: 308px;
  width: 100%;
  height: 56px;
  padding: 18px 40px;
  font-family: 'Neuzeit Grotesk', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  border: 0;
  outline: none;

  &:hover {
    background: #c95865;
  }
`;

export const AgreementsContainer = styled.div`
  display: flex;
  align-items: center;

  & > input {
    margin: 0;
    border: 1px solid #99a4c7;
    border-radius: 1px;
    display: grid;
    font-size: 0.65rem;
    grid-template-columns: 1em auto;
    gap: 0.5em;
    cursor: pointer;
  }
`;

export const AgreementsText = styled.div`
  margin-left: 8px;

  a {
    text-decoration: none;
    color: #bc2e3e !important;
  }
`;
