import React, { useContext } from 'react';
import * as S from './RepurchaseConfirmation.styles';
import { confirmRepurchase } from '../../utils/user';
import useLocalStorage from '../../hooks/useLocalStorage';
import { formatPrice } from '../../utils/formatPrice';
import { replaceUrlState } from '../../utils/replaceUrlState';
import { useCount } from '../../context/CountContext';
import { addPrefixToKey } from '../../utils/addPrefixToKey';
import FunnelContext from '../../context/FunnelContext';

const RepurchaseConfirmationV1 = props => {
  const {
    onetime,
    subscription,
    bumpOfferIndex,
    bumpOfferChecked,
    userDetails,
    currentCategory,
    setCurrentLayer
  } = useContext(FunnelContext);

  const { agreementsText, hideSavings } = props;
  const [userAgree, setUserAgree] = useLocalStorage('userAgree', false);
  const [token] = useLocalStorage('authToken', null);
  const { state } = useCount();
  const { repurchaseQuantity: quantity } = state;

  const [taxRate] = useLocalStorage(0);
  const [extraObjects2, setExtraObjects2] = useLocalStorage('extraObjects', {});

  const isSubscription = currentCategory === 'subscriptions';
  const { products, bumpoffers } = isSubscription ? subscription : onetime;
  const product = products[quantity - 1];

  const { price, discounted_price: discountedPrice } = product?.checkoutData;
  const savings = price - discountedPrice;

  const bumpOfferPrice = bumpOfferChecked
    ? bumpoffers[bumpOfferIndex]?.checkoutData?.discounted_price
    : 0;
  const subTotal = discountedPrice + bumpOfferPrice;
  const savingsTotal = bumpOfferChecked
    ? bumpoffers[bumpOfferIndex]?.checkoutData?.split_price + savings
    : savings;
  const tax = taxRate * subTotal;

  return (
    <>
      <S.Check>
        <S.CheckPosition>
          <S.Text>Subtotal</S.Text>
          <S.Text>{formatPrice(subTotal)}</S.Text>
        </S.CheckPosition>
        <S.CheckPosition>
          <S.Text>Taxes</S.Text>
          <S.Text>{formatPrice(tax)}</S.Text>
        </S.CheckPosition>
        {!hideSavings && (
          <S.CheckPosition>
            <S.Text>Total Savings</S.Text>
            <S.Text>{formatPrice(savingsTotal)}</S.Text>
          </S.CheckPosition>
        )}
        <S.Total>
          <S.Text>Total</S.Text>
          <S.Text>{formatPrice(subTotal + tax)}</S.Text>
        </S.Total>
      </S.Check>
      <S.AgreementsContainer>
        <input
          type="checkbox"
          name="agreements"
          checked={!!userAgree}
          onChange={() => {
            setUserAgree(!userAgree);
          }}
        />
        <S.AgreementsText
          dangerouslySetInnerHTML={{ __html: agreementsText }}
        ></S.AgreementsText>
      </S.AgreementsContainer>
      <S.Button
        disabled={!userAgree}
        onClick={async () => {
          if (!userAgree) return;

          const variantIds = [
            { variant_id: product?.data?.variantId, quantity }
          ];
          bumpOfferChecked &&
            variantIds?.push({
              variant_id: bumpoffers[bumpOfferIndex]?.data?.variantId,
              quantity: 1
            });

          await confirmRepurchase({
            bearerToken: token,
            data: {
              variant_ids: variantIds,
              discount_codes: product?.discount_code_override
            }
          }).then(r => {
            if (r.status === 200) {
              setTimeout(() => {
                window.scroll({
                  top: 0,
                  left: 0,
                  behavior: 'smooth'
                });
              });
              const { billingAddress, shippingAddress } = userDetails;
              const billingAddressPrefixed = addPrefixToKey(
                billingAddress,
                'billing'
              );
              const shippingAddressPrefixed = addPrefixToKey(
                shippingAddress,
                'shipping'
              );
              setExtraObjects2({
                ...extraObjects2,
                ...billingAddressPrefixed,
                ...shippingAddressPrefixed
              });
              replaceUrlState(
                `${window.location.origin +
                  window.location.pathname}?layer=success`
              );
              setCurrentLayer('success');
            } else {
              replaceUrlState(
                `${window.location.origin +
                  window.location.pathname}?layer=error`
              );
            }
          });
        }}
      >
        Pay now
      </S.Button>
    </>
  );
};

export default RepurchaseConfirmationV1;
